import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'semantic-ui-react';

export interface TextControlProps {
  name: string;
  label?: string;
  inputProps?: any;
  prepend?: any;
  append?: any;
  required?: boolean;
  icon?: any;
  formikProps: any;
  onChange?: (value: any) => void;
}

const EmailControl: FunctionComponent<TextControlProps> = (
  props: TextControlProps
) => {
  const { name, label, icon, required, inputProps, formikProps, onChange } =
    props;
  const { handleChange, handleBlur, values, errors, touched } = formikProps;
  const isError = errors && errors[name];
  const isTouched = touched && touched[name];
  const inputHandleChange = useCallback(
    (e: any) => {
      handleChange(name)(e);
      if (onChange) onChange(e.target.value);
    },
    [handleChange, name, onChange]
  );
  return (
    <Form.Input
      fluid
      required={required}
      iconPosition="left"
      icon={icon}
      name={name}
      className="login-input-control"
      onChange={inputHandleChange}
      onBlur={handleBlur(name)}
      value={values[name]}
      label={label}
      error={!!isTouched && isError}
      {...inputProps}
    />
  );
};

export default EmailControl;
