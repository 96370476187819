import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useMutation } from 'react-relay';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import TOKEN_AUTH, {
  AuthTokenAuthMutation,
} from '@audiobook/relay/common-queries/__generated__/AuthTokenAuthMutation.graphql';

import logoMarker from '../../public/logo/Logo.svg';
import EmailInput from '../common/form/EmailControl.component';
import FormError from '../common/form/form-error';
import PasswordInput from '../common/form/password-control.component';
import LoadingIndicator from '../common/loading-indicator';

import standardRelayError from './standard-relay-error';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
  remember: Yup.boolean(),
});

const LoginForm = () => {
  const router = useRouter();
  const [wholeFormErrors, setWholeFormErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [signIn] = useMutation<AuthTokenAuthMutation>(TOKEN_AUTH);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
    onSubmit: (values, { setErrors }) => {
      setLoading(true);
      setWholeFormErrors([]);
      const onError = standardRelayError(
        setLoading,
        setErrors,
        setWholeFormErrors
      );
      signIn({
        variables: {
          input: {
            ...values,
          },
        },
        onCompleted: () => {
          router.push('/');
        },
        onError,
      });
    },
  });

  return (
    <div>
      <LoadingIndicator loading={loading} msg="Signing in" />
      <Form onSubmit={formik.handleSubmit}>
        <div className="login-container">
          <div className="login-image-wrapper">
            <div className="login-image" />
            <div className="form-container">
              <img src={logoMarker} className="form-logo" />
              <div className="form-title">ADMİN GİRİŞ</div>
              <div className="form-frame">
                <div className="form-input">
                  <EmailInput
                    formikProps={formik}
                    name="email"
                    icon="mail"
                    inputProps={{
                      placeholder: 'Email',
                    }}
                  />
                </div>
                <div className="form-input">
                  <PasswordInput
                    name="password"
                    label=""
                    icon="lock"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={formik.errors && formik.errors.password}
                    touched={formik.touched && formik.touched.password}
                    inputProps={{
                      autoCapitalize: 'none',
                      placeholder: 'Password',
                    }}
                  />
                </div>

                <div className="form-button">
                  <Button type="submit">Giriş</Button>
                </div>
                {!!wholeFormErrors.length && (
                  <FormError errors={wholeFormErrors} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
