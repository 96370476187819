import jwt_decode from 'jwt-decode';

import {
  getUserFromSession,
  loadSession,
  saveSession,
} from '@audiobook/relay/session/compat';
export {
  deleteSession,
  loadSession,
  saveSession,
} from '@audiobook/relay/session/compat';

import { setIsInvalidSession } from './environment';
import { apiRefreshToken } from './refreshToken.utils';

export type { Session, MobileSession } from './session/types';

export class TokenError extends Error {
  response: Record<string, unknown>;

  constructor(name: string, message: string, resp: Record<string, unknown>) {
    super(message);
    this.name = name;
    this.response = resp;
  }
}

export const isTokenExpired = (token: string): boolean => {
  let decoded;
  try {
    decoded = jwt_decode<{ exp?: number }>(token);
  } catch (e) {
    console.warn(e);
  }
  if (!decoded) return true;
  const nowTime = Date.now() / 1000;
  if (decoded.exp && nowTime < decoded.exp) {
    return false;
  }
  return true;
};

export async function getOrRefreshToken(): Promise<string | null> {
  const session = await loadSession();
  if (session && session.token && session.refreshToken) {
    if (isTokenExpired(session.token)) {
      let response;
      try {
        response = await apiRefreshToken(session.refreshToken).toPromise();
      } catch (err) {
        throw new TokenError('SessionExpired', 'Session expired', { err });
      }
      if (response?.response.errors) {
        throw new TokenError(
          'SessionExpired',
          'Session expired',
          response.response.errors
        );
      }
      const { token } = response.response.data.refreshToken;

      const user = getUserFromSession(session);
      await saveSession(user, token, session.refreshToken, true);
      return token;
    }
    return session.token;
  }
  return null;
}

export async function getAuthHeader(): Promise<string | null> {
  let token;
  try {
    token = await getOrRefreshToken();
  } catch (err) {
    if (typeof err === 'object') {
      console.error(err, (err as TokenError).response);
      if ((err as TokenError).name === 'SessionExpired') {
        setIsInvalidSession(true);
      }
    } else {
      console.error(err);
    }
    token = null;
  }
  if (token) {
    return `JWT ${token}`;
  }
  return null;
}
