import { FunctionComponent, createContext, useReducer } from 'react';

interface Props {
  children: any;
}

const Store = createContext<{
  state: any;
  dispatch: (action: any) => void;
}>({
  state: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

const reducer = (state: any, action: any) => {
  return state;
};

const AppContext: FunctionComponent<Props> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer<any>(reducer, {});

  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
};

export { Store, AppContext };
