import * as Sentry from '@sentry/nextjs';
import React, { FunctionComponent } from 'react';

import { loadSession } from '@audiobook/relay/session.utils';
import useAuthState from '@audiobook/relay/useAuthState.hook';

import AuthForm from './AuthForm';
import LoadingIndicator from './LoadingIndicator.component';

const AuthInit: FunctionComponent<React.PropsWithChildren<unknown>> = (
  props
) => {
  const { children } = props;
  const session = loadSession();
  const authState = useAuthState(session);
  const { id, email } = session?.user || {};

  React.useEffect(() => {
    if (id) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id,
          email: email || '',
        });
      });
    }
  }, [id, email]);

  if (authState === 'authenticated') {
    return <>{children}</>;
  }
  if (authState === 'unsure') {
    return <LoadingIndicator loading msg="Authenticating" />;
  }
  if (authState === 'not-authenticated') {
    return <AuthForm />;
  }
  return null;
};

export default AuthInit;
