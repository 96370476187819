import { useEffect, useState } from 'react';

import {
  MobileSession,
  Session,
  getOrRefreshToken,
  isTokenExpired,
} from './session.utils';

type AuthStateType = 'unsure' | 'authenticated' | 'not-authenticated';

const useAuthState = (
  session: Session | MobileSession | undefined
): AuthStateType => {
  const [authState, setAuthState] = useState<AuthStateType>('unsure');

  useEffect(() => {
    if (session && session.token) {
      if (!isTokenExpired(session.token)) {
        setAuthState('authenticated');
        return;
      }
      getOrRefreshToken()
        .then(() => {
          setAuthState('authenticated');
        })
        .catch(() => {
          setAuthState('not-authenticated');
        });
      return;
    } else {
      setAuthState('not-authenticated');
    }
    return;
  }, [session]);

  return authState;
};

export default useAuthState;
