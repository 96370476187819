import {
  Environment,
  FetchFunction,
  Network,
  QueryResponseCache,
  RecordSource,
  Store,
} from 'relay-runtime';
import { fetch } from 'whatwg-fetch';

import Sentry from '@audiobook/relay/sentry';

import { getAuthHeader, saveSession } from './session.utils';
import { backendUrl } from './utils';

let _GLOBAL_IS_BACKEND_OFF = false;
export const setIsBackendOff = (isBackendOff: boolean) => {
  _GLOBAL_IS_BACKEND_OFF = isBackendOff;
};

export const getIsBackendOff = () => {
  return _GLOBAL_IS_BACKEND_OFF;
};

let _INVALID_SESSION = false;
export const setIsInvalidSession = (isInvalidSession: boolean) => {
  _INVALID_SESSION = isInvalidSession;
};

export const getIsInvalidSession = () => {
  return _INVALID_SESSION;
};

const oneMinute = 60 * 1000;
const cache = new QueryResponseCache({ size: 250, ttl: oneMinute });
export function clearCache(): void {
  cache.clear();
}
const fetchQuery: FetchFunction = async (
  operation,
  variables,
  cacheConfig,
  uploadables
) => {
  const queryID = operation.text;
  if (!queryID) {
    throw new Error('queryID is null');
  }
  const isMutation = operation.operationKind === 'mutation';
  const isQuery = operation.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;
  const fromCache = cache.get(queryID, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  let body;
  const headers: Record<string, string> = {
    Accept: 'application/json',
  };
  const authHeader = await getAuthHeader();
  if (authHeader) {
    headers['Authorization'] = authHeader;
  }

  if (uploadables) {
    if (!FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    const formData = new FormData();
    const queryStr = '{"query":' + JSON.stringify(queryID.replace(/\n/g, ''));
    const variablesStr = '"variables":' + JSON.stringify(variables) + '}';
    const operations = queryStr + ',' + variablesStr;
    formData.append('operations', operations.trim());

    Object.entries(uploadables).forEach(([key, uploadable]) => {
      formData.append(key, uploadable);
    });
    body = formData;
  } else {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify({
      query: queryID,
      variables,
    });
  }

  try {
    const resp = await fetch(backendUrl('graphql/'), {
      method: 'POST',
      headers,
      body,
    });
    if (!resp.ok && resp.status !== 400) {
      // 400 is an error response in graphql_django
      console.error(`HTTP status ${resp.status}`);
      Sentry.withScope(async (scope) => {
        scope.setContext('request', { queryID, variables });
        try {
          const response = await resp.json();
          console.warn('RESPONSE', response); // FIXME
          scope.setContext('response', response);
        } catch (err) {
          /* ignore */
        }
        Sentry.captureMessage(`API call returned status ${resp.status}`);
      });
      if (resp.status > 499) {
        setIsBackendOff(true);
      }
      return;
    }
    const response = await resp.json();
    if (isQuery && response.data) {
      cache.set(queryID, variables, response);
    }
    // Clear cache on mutations
    if (isMutation) {
      cache.clear();
    }

    if (response && response.errors) {
      throw new Error(response.errors[0].message);
    }

    if (response && response.data.logIn) {
      const { token, refreshToken, userId } = response.data.logIn;
      saveSession(userId, token, refreshToken);
    }
    if (response && response.data.tokenAuth) {
      const { token, refreshToken, user } = response.data.tokenAuth;
      saveSession(user, token, refreshToken);
    }
    return response;
  } catch (e) {
    const err = e as Error;
    if (
      ['NoSession', 'SessionExpired'].includes(err.name) ||
      err.message === 'Error decoding signature'
    ) {
      setIsInvalidSession(true);
    } else if (err.message === 'Network request failed') {
      setIsBackendOff(true);
    } else {
      console.log('err: ', err);
      throw err;
    }
  }
};

const source = new RecordSource();
const store = new Store(source);
const network = Network.create(fetchQuery);

export default new Environment({
  network,
  store,
});
