import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';

import RelayEnvironment from '@audiobook/relay/environment';

import AuthInit from '../components/auth/AuthInitComponent';
// eslint-disable-next-line import/order
import logo from '../public/logo/Logo.svg';
import './styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'semantic-ui-css/semantic.min.css';

import { AppContext } from '../src/store';

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>KitapDinler</title>
        <link rel="shortcut icon" href={logo} />
      </Head>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <AuthInit>
          <AppContext>
            <Component {...pageProps} />
          </AppContext>
        </AuthInit>
      </RelayEnvironmentProvider>
    </>
  );
}

export default CustomApp;
